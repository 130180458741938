import React from 'react'
import Layout from '../../components/layout'

import './index.css'

const SuccessPage = (props) => {
  return (
    <Layout location={props.location}>
      <div className="success">
        <h1>Success! We'll be in touch soon 👍</h1>
      </div>
    </Layout>
  )
}

export default SuccessPage

